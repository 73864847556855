<template>
    <div></div>
</template>
<script>
export default {
    name: 'AuthEntryPoint',
    data() {
        return {
            user: this.$auth.user(),
        };
    },
    mounted() {
        console.log(this.$auth.user());
        if (this.$auth.user().role === 'StoreManager') {
            if (this.user.store.license_terms_accepted === false) {
                this.$router.push({ name: 'accept-store-license' }).catch((e) => console.log(e));
            } else if (this.user.store.privacy_terms_accepted === false) {
                this.$router.push('/store/accept-privacy').catch((e) => console.log("kkk",e.message));
            } else {
                this.$router.push({ name: 'home'});
            }  
        } else if (this.$auth.user().role === 'Admin') {
            this.$router.push( { name: 'adminCustomersList' }).catch((e) => console.log(e));
        } else if (this.$auth.user().role === 'Doctor') {
            this.$router.push({ name: 'home' }).catch((e) => console.log(e));
        }
    }
}
</script>
